import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { withAuthenticationRequired, Auth0Provider, useAuth0 } from '@auth0/auth0-react'

import App from './components/app/app'
import Login from './components/login/login'
import { isDev } from './common_functions'
import { KEYS, setEnv } from './constants'
import { EIDSignerProvider } from './contexts/eIDSigner'

export const history = createBrowserHistory()

const ProtectedRoute = ({ component, ...args }) => {
  const { isAuthenticated, user } = useAuth0()
  if (isAuthenticated && user) {
    setEnv(isDev(user) ? KEYS.DEV : KEYS.PROD)
  }
  return <Route
    component={withAuthenticationRequired(component, { onRedirecting: () => <Login ignoreLoading /> })} {...args} />
}

const onRedirectCallback = (appState) => {
  // // Use the router's history module to replace the url
  // history.replace({
  //   pathname: appState?.returnTo || window.location.pathname,
  //   search: ''
  // })
}

const {
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_AUDIENCE,
} = process.env

const AppReal = React.memo(function AppReal () {
  console.log(`RenderingAppReal`)
  return <Auth0Provider
    domain={REACT_APP_AUTH_DOMAIN}
    clientId={REACT_APP_AUTH_CLIENT_ID}
    audience={REACT_APP_AUTH_AUDIENCE}
    redirectUri={`${window.location.origin}/login`}
    onRedirectCallback={onRedirectCallback}
  >
    <EIDSignerProvider>
      <Router history={history}>
        <Switch>
          <Route path='/login' to='/' component={Login} />
          <ProtectedRoute component={App} />
        </Switch>
      </Router>
    </EIDSignerProvider>
  </Auth0Provider>
})

export default AppReal
